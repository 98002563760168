@import '../../../index.scss';

.SignUp {
    background-color: #434348;
    width: auto;
    height: auto;
    @media (min-width: 768px) {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #888;
    background-color: rgba($color: white, $alpha: 0.81);
    gap: 42px;
    padding: 1rem;
    height: 100vh;
    @media (min-width: 768px) {
        border-radius: 20px;
        width:30rem;
        box-shadow: 0px 4px 4px;
        padding: 2rem;
        height: auto;
    }
}

.Logo {
    height: 87px;
}

.Link {
    align-self: flex-end;
    color: #0A0A0A;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    word-wrap: break-word
}

