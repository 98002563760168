.AvatarInput {}

.personal_image {
  text-align: center;
}
.personal_image input[type="file"] {
  display: none;
}
.personal_figure {
  position: relative;
  width: 120px;
  height: 120px;
}
.personal_avatar {
  cursor: pointer;
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  border-radius: 100%;
  border: 2px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: all ease-in-out .3s;
}
.personal_avatar:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
.personal_figcaption {
  cursor: pointer;
  position: absolute;
  top: 0px;
  width: inherit;
  height: inherit;
  border-radius: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out .3s;
}
.personal_figcaption:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, .5);
}
.personal_figcaption > img {
  margin-top: 32.5px;
  width: 50px;
  height: 50px;
}