@import '../../../index.scss';

.UpdateSolicitationModal {

}

.buttonContainer {
    display: flex;
    width: 100%;
    gap: 2rem;
    margin-top: 1rem;
}