@import '../../../index.scss';
.TripAddressInput {}

.addressHeader{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.sectionContainer{
    display:flex;
    flex-direction: column;
    gap: 1rem;
    margin:0;
    margin-bottom: .5rem;
}

.icon{
    cursor: pointer;
    color: red;
}