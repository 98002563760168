@import '../../../index.scss';

.AddressesDetails {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

h6 {
    margin-block-start: 0;
    margin-block-end: 0;
}

.InputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Buttons {
    width: 60%;
    @media (min-width: 768px) {
        width: 30rem;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.btn {
    width: max-content;
    padding: 1rem 9rem;
    margin-bottom: 1rem;
}

.textSuccess {
    color: green;
}

.textDanger {
    color: red;
}