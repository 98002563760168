
.PrimaryButton {
    background-color: $primary-color;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid $primary-color;
    color: white;
    border-radius: .5rem;
    width: 100%;
}


.DangerButton {
    background-color: $danger-color;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid $danger-color;
    color: white;
    border-radius: .5rem;
    width: 100%;
}

.buttonContainerSm {
    @media (min-width:768px) {
        width: 15rem;
    }
    width: 100%;
    align-self: center;
}

.buttonContainer {
    @media (min-width:768px) {
        width: 30rem;
    }
    width: 100%;
    align-self: center;
}

.SecondaryButton {
    background-color: transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid black;
    width: 100%;
    color: black;
    border-radius: .5rem;
}

.TextInput {
    border:none;
    border-bottom: 1px solid black;
    background-color: transparent;
    width: 100%;
    &::placeholder{
        color: #888;
    }
}

.sectionContainer {
    background-color: white;
    border-radius: 20px;
    padding: 1.5rem;
    margin: 1rem;
}

.mdShow {
    display: block;
    @media (min-width: 768px) {
        display: none;
    }
}

.mdHide {
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
}

.radioInput {
    display: flex;
    gap: .5rem;
    margin: .25rem;
}

.textArea {
    resize: vertical;
    width: 100%;
    
}

.icon_edit {
    background-image: url('../images/edit.svg');
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}