@import '../../../../index.scss';
.GuestDetails {}

.textTitle {
    font-weight: bold;
}

.buttons {
    width: 60%;
    @media (min-width: 768px) {
        width: 30rem;
    }
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: .5rem;
}

.InputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.StatusContainer {
    background-color: white;
    border-radius: 20px;
    padding: .5rem;
    width: auto;
    text-align: center;
}

.StatusTitle {
    margin-block-start: .5rem;
    margin-block-end: .5rem;
}