.formGrid1 {
    display: grid;
    @media (min-width:768px) {
        grid-template-columns: 1fr;
        column-gap: 2rem;
    }
    grid-auto-rows: 100px;
    grid-template-columns: 1fr;
}

.formGrid2 {
    display: grid;
    @media (min-width:768px) {
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
    }
    row-gap: 1rem;
    grid-auto-rows: 2rem;
    grid-template-columns: 1fr;
}

.formGrid3 {
    display: grid;
    @media (min-width:768px) {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 2rem;
    }
    row-gap: 1rem;
    grid-auto-rows: 2rem;
    grid-template-columns: 1fr;
}

.formGrid4 {
    display: grid;
    @media (min-width:768px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 2rem;
    }
    grid-auto-rows: 2rem;
    row-gap: 1rem;
    grid-template-columns: 1fr;
}

.formGrid5 {
    display: grid;
    @media (min-width:768px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        column-gap: 2rem;
    }
    grid-template-columns: 1fr;
}