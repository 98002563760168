@import '../../../index.scss';

.CarDetails {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.personal_avatar {
    cursor: pointer;
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    border-radius: 100%;
    border: 2px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out .3s;
}

h6 {
    margin-block-start: 0;
    margin-block-end: 0;
}

.InputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Buttons {
    width: 60%;
    @media (min-width: 768px) {
        width: 30rem;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.btn {
    width: max-content;
    padding: 1rem 9rem;
    margin-bottom: 1rem;
}

.textSuccess {
    color: green;
}

.textDanger {
    color: red;
}