.Header {
    margin: 4rem 2rem 2rem 2rem;
    .HeaderItems {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: column;
        gap:1rem;
        @media (min-width: 768px) {
            flex-direction: row;
        }
    }
}
