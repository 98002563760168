
$primary-color: #FFC817;
$danger-color: #CF2600;
$success-color: #2DAB32;

// Backgrounds
$primary-bg: #F6F6F6;
$forms-bg: white;

// Text

$placeholder-color: #999999;


