@import '../../../index.scss';
.CarModal {
    text-align: center;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.marginTopBottom {
    margin-top: 1rem;
    margin-bottom: 1rem;
}