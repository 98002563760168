@import '../../../../index.scss';

.Create {}

.formContainer {
    background-color: transparent;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 10px;
    padding: .5rem;
    margin: .5rem;
}

.HeaderContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: flex-end;
}

.buttonsGroup {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
    flex-direction: row;
}

.buttonContainer {
    width: 28rem;
}